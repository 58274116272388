<template>
  <el-dialog :close-on-click-modal="false"
    v-model="dialogFormVisible"
    title="查看报表数据"
    width="90%"
    destroy-on-close
    append-to-body
    >
  <div  v-loading.fullscreen.lock="fullscreenLoading">
    <!-- 一般企业收入明细表 -->
    <div class="big_box4">
      <div class="top_title3">
        <h5>一般企业收入明细表</h5>
        <!-- <div class="bottom_font clearfix">
          <h6 class="left">纳税人名称：<span>{{comName}}</span></h6>
          <h6 class="center"><span>2020-2-25</span></h6>
          <h6 class="right">
            <p>单位：元</p>
            <p>纳税人识别号：<span>{{taxNo}}</span></p>
          </h6>
        </div> -->
      </div>
      <table class="content" cellspacing="0" width="100%">
        <tr class="top_bg">
         <td class="center" style="width:4%;">行次</td>
         <td class="center" style="width:76%;">项目</td>
         <td class="center" style="width:20%;">金额</td>
        </tr>
      </table>
      <div class="fixed_box_b">
        <table class="content" cellspacing="0">
          <tr v-for="item in info" :key="item.id">
            <td class="center" style="width:4%;">{{item.type}}</td>
            <td style="width:76%;">{{item.project}}</td>
            <td class="blue_box" style="width:20%;"><input type="text" v-model="item.amount"></td>
          </tr>
          
        </table>
      </div>
      <div class="save_box">
        <el-button type="primary" size="mini" @click="saveDetail()">保存</el-button>
      </div>
    </div>
    
    <!-- 一般企业收入明细表 end -->
  </div>
</el-dialog>

</template>
<script>
import { yearInfo, yearEdit } from "@/api/year"
export default {
  name: "A101010",
  props: {
    tableYearName: {
      default:'',
      type:String
    },
    comId:{
      default:0,
      type:Number
    },
  },
  data() {
    return {
      comName: '',
      taxNo: '',
      info:[],
      fullscreenLoading:false,
      dialogFormVisible:false
    };
  },
  created() {
    this.comName = this.$store.getters['user/comInfo'].comName
    this.taxNo = this.$store.getters['user/comInfo'].taxNo
  },
  methods: {
    getParams() {
      this.dialogFormVisible = true
      // const tableName = this.$route.params.tableName;
      const tableName = 'year_a101010'
      this.tableName = tableName
      if (tableName) {
        this.getDetail({comId:this.comId,period:'2022',tableName:this.tableYearName});
        this.listLoading = true;
      } else {
        this.listLoading = false;
      }
    },
    getDetail(e) {
      yearInfo(e).then(response => {
        this.info = response.data.data;
        console.log(this.info)
      });
    },
    // 点击保存
    saveDetail(){
      this.fullscreenLoading = true
      yearEdit({comId:this.comId,year:'2022',tableName:this.tableName,items:this.info}).then(res => {
        if(res.data.msg == 'success'){
          this.$message({
            message: "保存成功",
            type: "success"
          });
        }
        this.getDetail({comId:this.comId,period:'2022',tableName:this.tableYearName})
        this.fullscreenLoading = false
      });
    }
    
  }
};
</script>

<style lang="scss" scoped>
.border_none .el-select{
  width: 100% !important;
}
.center{
  text-align: center;
}
.border_none .el-input__inner{
  width: 100% !important;
  background-color: none !important;
}

.el-radio+.el-radio {
    margin-left: 7px;
}
.el-radio__label{
  font-size: 12px !important;
}
.input_width input{
  width:60px !important;
  margin: 0 5px;
}
.width_big{
  width: 30%;
}
.width_small{
  width: 20%;
}
.width_medium{
  width: 18%;
}
.width_ss{
  width: 15%;
}
.width_xs{
  width:13%;
}
.gary_box{
  background: #f8f8f8;
  text-align: right;
  padding-right: 20px !important;
}
.blue_box{
  background: #EBF5FF ;
}
.el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 180px !important;
}
.save_box{
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.content{
  // width: 2000px;
  width: 100%;
  margin: 0 auto;
  border:1px solid #c2c2c2;
  border-bottom: none;
  border-right: none;
  margin-top: 10px;
  span{
    line-height: 28px;
    font-size: 14px;
  }
  tr{
    padding: 0;
    margin: 0;
  }
  td{
    border-right:1px solid #c2c2c2;
    border-bottom:1px solid #c2c2c2;
    line-height: 28px;
    padding:0 6px;
    color: #666;
    font-size: 12px;
  }
  input{
    width: 100%;
    line-height: 28px;
    // padding: 0 6px;
    text-align: right;
    font-size: 12px;
    padding-right: 14px;
  }
}
.left_k{
  text-indent: 2em;
}
.left_indent{
  text-indent: 5em;
}
.top_title3{
  width: 100%;
  margin: 0 auto;
  h5{
    font-size: 20px;
    color: #333;
    font-weight: 700;
    text-align: center;
  }
  .bottom_font{
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
    // position: relative;
    h6{
      float: left;
      width: 33.33%;
      font-size: 12px;
      color: #333;
      font-weight: normal;
      // position: absolute;
      // bottom: 0;
    }
    h6:nth-child(1){
      padding-top: 10px;
    }
    h6:nth-child(2){
      padding-top: 10px;
    }
  }
}
.center{
  text-align: center;
}
.left{
  text-align: left;
}
.right{
  text-align: right;
}
.weight{
  font-weight: 700;
}
.row_box{
  border-bottom: 1px solid #eee;
}
.big_box4{
  width: 98%;
  // padding: 40px;
  background: #fff;
  // box-shadow: 0 2px 10px #ddd;
  margin: 0 auto;
  margin-top: 20px;
  box-sizing: border-box;
}
.qsly{
  margin: 4px 5px 0 0;
  float: right;
  color: #666;
  // border: 0;
  // background: #fff;
  cursor: pointer;
}
</style>



