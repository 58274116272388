<template>
  <div class="app-container">
    <div style="font-size:12px;color:red;margin-bottom: 10px;">
      （汇算清缴鲸算盘系统暂时不可申报，申报开放时间另行通知）
    </div>
    <div class="top_btns">
      <div class="left_box">
        <el-date-picker v-model="listQuery.year" type="year" value-format="YYYY" placeholder="选择年份"  @change="getList" size="small" style="width:120px"/>
        <!-- <el-date-picker @change="getList" :clearable="false" v-model="listQuery.period" type="month" value-format="YYYYMM" size="small" placeholder="日期" style="width: 160px;margin-right:5px;"></el-date-picker> -->
        <el-input placeholder="请输入企业名称" v-model="listQuery.name" style="width: 160px;margin-left:10px" class="filter-item" size="small" @keyup.enter="getList"/>
        <el-button class="filter-item1" type="primary" size="small" @click="getList" style="margin-right:10px">
          <el-icon><Search /></el-icon><span  > 搜索</span>
        </el-button>
        <search @success="getList">
          <el-form style="margin:11px 0px 0px 6px;width:500px" class="styleForm">
            <el-form-item label="财务报表取数结果：" :label-width="formLabelWidth">
              <selectYearAcessStatus v-model:allStatus="listQuery.allStatus1"></selectYearAcessStatus>
            </el-form-item>
            <el-form-item label="财务报表申报结果：" :label-width="formLabelWidth">
              <selectYearBusinessAcessStatus v-model:allStatus="listQuery.allStatus2"></selectYearBusinessAcessStatus>
            </el-form-item>
            <el-form-item label="汇算清缴采集结果：" :label-width="formLabelWidth">
              <selectYearBusinessCjStatus v-model:allStatus="listQuery.allStatus3"></selectYearBusinessCjStatus>
            </el-form-item>
            <el-form-item label="汇算清缴取数结果：" :label-width="formLabelWidth">
              <selectYearAcessStatus v-model:allStatus="listQuery.allStatus4"></selectYearAcessStatus>
            </el-form-item>
            <el-form-item label="汇算清缴申报结果：" :label-width="formLabelWidth">
              <selectYearBusinessAcessStatus v-model:allStatus="listQuery.allStatus5"></selectYearBusinessAcessStatus>
            </el-form-item>
          </el-form>
        </search>
      </div>
      <div class="right_box">
        <!-- <el-button size="small" type="primary" @click="declaration()"   >
          <el-icon><Checked /></el-icon><span  > 批量检查</span>
        </el-button> -->
      </div>
    </div>
    <el-table :data="list" style="width: 100%" :height="contentStyleObj" v-loading="loading">
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column align="center" prop="id" type="selection" width="55" />
      <el-table-column align="center" type="index" label="编号" width="60" />
      <el-table-column label="">
      <el-table-column prop="name" label="公司名" min-width="260">
        <template #default="scope">
          <span v-if="scope.row.type == '1'" class="xgmStyle">小</span>
          <span v-if="scope.row.type == '2'" class="ybStyle">般</span>
          <span @click="$goEtax(scope.row.comId)" style="cursor:pointer;color:#39b0d2" type="text" >{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      </el-table-column>
      <el-table-column label="财务报表">
        <el-table-column prop="state" label="取数（第一步）"  min-width="230">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$yearAcessIconStatus(scope.row.taskStatus1)"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog1?scope.row.errLog1:''" :disabled="!scope.row.errLog1 && !scope.row.bussinessLog" placement="top-start">
                  <el-button size="small" :class="$yearAcessWordStatus(scope.row.taskStatus1)" style="border: 0;font-size: 14px;">{{$yearAcessStatus(scope.row.taskStatus1)}}</el-button>
                </el-tooltip>
                <el-button type="text" size="small" @click="cwbbAcess(scope.row)">取数</el-button>
                <el-button @click="checkCwbbYear(scope.row)" :disabled="scope.row.taskStatus1 != 3" size="small" type="text">查看</el-button>

              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="city" label="申报（第二步）"   min-width="230">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$batchSendIconSb(scope.row.taskStatus2, scope.row.bussinessStatus2)"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.bussinessLog2?scope.row.bussinessLog2:'' + scope.row.errLog2?scope.row.errLog2:''" :disabled="!scope.row.errLog2 && !scope.row.bussinessLog2" placement="top-start">
                  <el-button size="small" :class="$batchSendWordSb(scope.row.taskStatus2, scope.row.bussinessStatus2)" style="border: 0;font-size: 14px;">{{$batchSendYearSb(scope.row.taskStatus2,scope.row.bussinessStatus2)}}</el-button>
                </el-tooltip>
                <el-button type="text" size="small" @click="cwbbDeclare(scope.row)" :disabled="scope.row.taskStatus1 != 3">申报</el-button>

              </p>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="汇算清缴">
        <el-table-column prop="state" label="采集（第三步）"  min-width="230">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$setUpStatusJzCjIcon(scope.row.taskStatus3, scope.row.bussinessStatus3)"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.bussinessLog3?scope.row.bussinessLog3:'' + scope.row.errLog3?scope.row.errLog3:''" :disabled="!scope.row.errLog3 && !scope.row.bussinessLog3" placement="top-start">
                  <el-button size="small" :class="$setUpStatusGsWordZf(scope.row.taskStatus3, scope.row.bussinessStatus3)" style="border: 0;font-size: 14px;">{{$setUpStatusJzCj(scope.row.taskStatus3,scope.row.bussinessStatus3)}}</el-button>
                </el-tooltip>
                <el-button type="text" size="small" @click="yearCollect(scope.row)" :disabled="scope.row.bussinessStatus2 != 2">采集</el-button>
                <el-button @click="checkTable(scope.row)" :disabled="scope.row.bussinessStatus3 != 2" size="small" type="text">查看</el-button>

              </p>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="city" label="取数（第四步）"  min-width="230">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$yearAcessIconStatus(scope.row.taskStatus4)"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.bussinessLog?scope.row.bussinessLog:'' + scope.row.errLog4?scope.row.errLog4:''" :disabled="!scope.row.errLog4 && !scope.row.bussinessLog" placement="top-start">
                  <el-button size="small" :class="$yearAcessWordStatus(scope.row.taskStatus4)" style="border: 0;font-size: 14px;">{{$yearAcessStatus(scope.row.taskStatus4)}}</el-button>
                </el-tooltip>
                <el-button type="text" size="small" @click="yearAcess(scope.row)" :disabled="scope.row.bussinessStatus3 != 2">取数</el-button>
                <el-button  @click="checkQysdYear(scope.row)" size="small" type="text" :disabled="scope.row.taskStatus4 != 3">查看</el-button>
              </p>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="汇算清缴申报">
        <el-table-column prop="state" label="申报（第五步）"  min-width="230">
          <template #default="scope">
            <div class="item_icon">
              <p>
                <i :class="$batchSendIconSb(scope.row.taskStatus5, scope.row.bussinessStatus5)"></i>
                <el-tooltip class="item" effect="dark" :content="scope.row.bussinessLog5?scope.row.bussinessLog5:'' + scope.row.errLog5?scope.row.errLog5:''" :disabled="!scope.row.errLog5 && !scope.row.bussinessLog5" placement="top-start">
                  <el-button size="small" :class="$batchSendWordSb(scope.row.taskStatus5, scope.row.bussinessStatus5)" style="border: 0;font-size: 14px;">{{$batchSendYearSb(scope.row.taskStatus5,scope.row.bussinessStatus5)}}</el-button>
                </el-tooltip>
                <el-button type="text" size="small" @click="yearDeclare(scope.row)" :disabled="scope.row.taskStatus4 != 3">申报</el-button>

              </p>
            </div>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>

    <checkYearTable ref="checkYearTable"></checkYearTable>
    <checkCwbbYear ref="checkCwbbYear"></checkCwbbYear>
    <checkQysdYear ref="checkQysdYear"></checkQysdYear>


  </div>
</template>

<script>
import { companyYearList } from "@/api/company"
import { yearSave} from "@/api/year";
import selectYearBusinessAcessStatus from "./components/select/selectYearBusinessAcessStatus.vue"
import selectYearBusinessCjStatus from "./components/select/selectYearBusinessCjStatus"
import selectYearAcessStatus from "./components/select/selectYearAcessStatus.vue"
import checkYearTable  from "./components/checkYearTable.vue"
import checkCwbbYear  from "./components/checkCwbbYear.vue"
import checkQysdYear  from "./components/checkQysdYear.vue"
import { sendTask } from "@/api/collection"


export default {
  name: 'yearSb',
  components:{
    selectYearBusinessAcessStatus,
    selectYearBusinessCjStatus,
    selectYearAcessStatus,
    checkYearTable,
    checkCwbbYear,
    checkQysdYear
  },

  data() {
    return {
      list:[],
      listQuery:{
        limit:20,
        page:1,
        name:'',
        year:'2022',
        allStatus1:[],
        allStatus2:[],
        allStatus3:[],
        allStatus4:[],
        allStatus5:[]
      },
      total:0,
      contentStyleObj:{},
      loading:false
    };
  },
  created(){
    this.init()
    this.getList()
    this.contentStyleObj= this.$getHeight(280)
  },

  mounted() {
    
  },

  methods: {
    init(){
      this.$alert('<strong>该功能暂内测中,现无法使用</strong>', {
        confirmButtonText: '确定',
        dangerouslyUseHTMLString: true
      })
    },
    getList(){
      this.loading = true
      companyYearList(this.listQuery).then(res=>{
        this.loading = false
        this.list = res.data.data.list
        this.total = res.data.data.total
      }) 
    },
    // 财务报表
    // 取数
    cwbbAcess(row){
      let param = {
        type: 1,
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      yearSave(param).then(res => {
        this.loading = false

        if(res.data.msg == 'success'){
          this.$message({
            message: "取数成功",
            type: "success"
          });
          this.getList()
        }
      });
    },
    // 取数完查看
    checkCwbbYear(row){
      this.$refs.checkCwbbYear.getList(row)
    },
    // 申报
    cwbbDeclare(row){
      let param = {
        taskName: 'tax-cwyear-sb',
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false

        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          // this.$queueMessage(res.data.data.queue)
        }
      })
    },
    // 汇算清缴
    // 采集
    yearCollect(row){
      let param = {
        taskName: 'tax-year-cj',
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false

        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    },
    // 采集完查看
    checkTable(row){
      this.$refs.checkYearTable.getList(row)
    },
    // 取数
    yearAcess(row){
      let param = {
        type: 2,
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      yearSave(param).then(res => {
        this.loading = false

        if(res.data.msg == 'success'){
          this.$message({
            message: "取数成功",
            type: "success"
          });
          this.getList()
        }
      });
    },
    // 取数完查看
    checkQysdYear(row){
      this.$refs.checkQysdYear.getList(row)
    },
    // 申报
    yearDeclare(row){
      let param = {
        taskName: 'tax-year-sb',
        comIds: [row.comId],
        period:this.listQuery.year
      }
      this.loading = true
      sendTask(param).then(res => {
        this.loading = false

        if(res.data.msg == 'success') {
          this.$qzfMessage('任务已发起',3)
          this.getList()
          this.$queueMessage(res.data.data.queue)
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.top_btns {
  margin-bottom: 40px;
  .left_box{
    float: left;
  }
  .right_box {
    float: right;
    text-align: right;
  }
}
</style>