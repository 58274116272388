<template>
	<el-checkbox-group size="small" v-model="allStatus">
		<el-checkbox-button v-for="item in options" :key="item.value" :label="item.value" @change="changeValue">{{item.label}}</el-checkbox-button>
	</el-checkbox-group>
  
</template>
<script>
export default {
  name: 'selectYearAcessStatus',
  props:{
		allStatus:0
	}, 
  data() {
    return {
      options: [
        {
          value: "task-0",
          label: '未取数',
        },
        {
          value: "task-1",
          label: '取数中',
        },
        {
          value: "task-3",
          label: '取数成功',
        },
        {
          value: "task-4",
          label: '取数失败',
        },
      ],
    };
  },

  mounted() {
    
  },

  methods: {
    changeValue() {
      this.$emit('update:allStatus', this.allStatus)
      this.$emit("success",this.allStatus)
    },   
  },
};
</script>

<style lang="scss" scoped>

</style>