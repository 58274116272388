<template>
  <div>
    <el-dialog :close-on-click-modal="false"
      v-model="dialogFormVisible"
      title="查看报表数据"
      width="90%"
      destroy-on-close
      >
      <yearFinance :comId="comId" tableYearName="year_zcfz" ref="yearFinance" ></yearFinance>
    </el-dialog>
  </div>
</template>

<script>
import yearFinance from "@/components/yearFinance/index"; //列表组件

export default {
  name: 'checkCwbbYear',
  components:{
    yearFinance
  },
  data() {
    return {
      dialogFormVisible:false,
      comId:''
    };
  },

  mounted() {
    
  },

  methods: {
    getList(e){
      this.comId = e.comId
      this.dialogFormVisible =true
    }
  },
};
</script>

<style lang="scss" scoped>

</style>